import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import components from './AmplifyFormComponents'
import {Authenticator, withAuthenticator} from '@aws-amplify/ui-react';
import {Amplify} from "aws-amplify"
import {I18n} from 'aws-amplify/utils';
import {translations, defaultDarkModeOverride, ThemeProvider} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';  // this is so stupid but its needed I guess
import {fetchAuthSession} from '@aws-amplify/auth';

I18n.putVocabularies(translations);
I18n.setLanguage('zh');


Amplify.configure({
    Auth: {
        Cognito: {
            awsRegion: "east-us-1",
            userPoolId: 'us-east-1_jauZsnL2Y',
            //userPoolEndpoint: 'https://mira-user.auth.us-east-1.amazoncognito.com/oauth2/authorize',
            userPoolClientId: 'gvcb2g4req8vmp1fq3s66i7j',
            mandatorySignIn: true, // Optional
            loginWith: {
                oauth: {
                    domain: 'https://mira-user.auth.us-east-1.amazoncognito.com/oauth2/authorize',
                    scope: [
                        'profile',
                        'openid',
                        'mira-api/call'
                    ],
                    redirectSignIn: 'http://localhost:3000', //TODO: add new url
                    redirectSignOut: 'http://localhost:3000', //TODO: add new url
                    responseType: 'code'
                }
            }
        }
    }
});

const theme = {
    name: 'my-theme',
    overrides: [
        {
            colorMode: 'dark',
            tokens: {
                colors: {
                    font: {
                        primary: {value: '#ffffff'},
                        secondary: {value: '#aaaaaa'},
                        tertiary: {value: '#aaaaaa'},
                    },
                    background: {
                        primary: {value: '#303030'},
                        secondary: {value: '#424242'},
                        tertiary: {value: '#424242'},
                    },
                    border: {
                        primary: {value: '#F5F5F5'},
                        secondary: {value: '#F7F7F7'},
                        tertiary: {value: '#FAEBD7'},
                    },
                },
            },
        },
    ],
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme} colorMode="dark">
            <Authenticator hideSignUp components={components} theme={theme}>
                {({signOut, user}) => (
                    <App signOut={signOut} user={user}/>
                )}
            </Authenticator>
        </ThemeProvider>
    </React.StrictMode>
);