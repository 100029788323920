import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    SvgIcon,
    Modal,
    Snackbar,
    Alert,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import PersonIcon from '@mui/icons-material/Person';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import {ReactComponent as TextBanner} from "./mira-banner.svg";
import {ReactComponent as Logo} from "./mira-logo.svg";
import {ReactComponent as HeadLogo} from "./mira-head.svg";
import {fetchAuthSession, getCurrentUser} from "@aws-amplify/auth";





const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            dark: '#FAEBD7',
            main: '#F5F5F5',
            light: '#F7F7F7'
        }
    },
});


const AreYouSureModal = ({open, setOpen, onConfirm, message}) => {

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        onConfirm().then(setOpen(false));
    };

    return (
        <Modal
            aria-labelledby="are-you-sure-modal-title"
            aria-describedby="are-you-sure-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
                position: "absolute",
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 24,
                width: '60vh',
                height: '20vh',

            }}
        >
            <Paper sx={{
                height: '100%',
                width: '100%',
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <Typography id="are-you-sure-modal-title" variant="h6" component="h2">
                    您确定吗？
                </Typography>
                <Typography id="are-you-sure-modal-description" sx={{mt: 2, textAlign: "center"}}>
                    {message}
                </Typography>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={handleClose} sx={{width: '20%'}}>
                        取消
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm} sx={{width: '20%'}}>
                        确定
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
}


const EditConversationNameModal = ({open, setOpen, onConfirm}) => {

    const [newName, setNewName] = useState("")

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        onConfirm(newName.trimEnd()).then();
        setNewName("")
        setOpen(false)
    };

    return (
        <Modal
            aria-labelledby="are-you-sure-modal-title"
            aria-describedby="are-you-sure-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
                position: "absolute",
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 24,
                width: '60vh',
                height: '30vh',

            }}
        >
            <Paper sx={{
                height: '100%',
                width: '100%',
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <Typography id="are-you-sure-modal-title" variant="h6" component="h2">
                    新对话名称
                </Typography>
                <TextField
                    onChange={(event) => {
                        if (event.target.value.length <= 100)
                            setNewName(event.target.value.trimStart())
                    }}
                    value={newName}
                    label="输入名称"
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={handleClose} sx={{width: '20%'}}>
                        取消
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        sx={{width: '20%'}}
                        disabled={!newName.length}
                    >
                        保存
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
}


const SignOutModal = ({open, setOpen, signOut, username}) => {

    const handleClose = () => {
        setOpen(false);
    };

    const handleSignOut = async () => {
        signOut();
        setOpen(false)
    };

    return (
        <Modal
            aria-labelledby="are-you-sure-modal-title"
            aria-describedby="are-you-sure-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
                position: "absolute",
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 24,
                width: '60vh',
                height: '20vh',

            }}
        >
            <Paper sx={{
                height: '100%',
                width: '100%',
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <Typography id="are-you-sure-modal-title" variant="h6" component="h2">
                    {username}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignOut}
                    sx={{width: '100%'}}
                >
                    登出
                </Button>
            </Paper>
        </Modal>
    );
}


const App = ({signOut, user}) => {
    // message types
    const userMessageType = "user"
    const responseMessageType = "assistant"
    const maxMessages = 50
    const maxConversations = 50

    // TODO: do not allow sending repeated messages until response
    const [conversations, setConversations] = useState([])
    const [currentConversation, setCurrentConversation] = useState({name: "", id: ""})
    const [messages, setMessages] = useState([])
    const [userMessage, setUserMessage] = useState('')
    const [openVerificationModal, setOpenVerificationModal] = useState(false)
    const [openConvoNameChangeModal, setOpenConvoNameChangeModal] = useState(false)
    const [openSignOutModal, setOpenSignOutModal] = useState(false)
    const [currentError, setCurrentError] = useState("")
    const [disableUI, setDisableUI] = useState(false);
    const [headers, setHeaders] = useState({});
    const [session, setSession] = useState(null);
    const scrollableAreaRef = useRef(null)
    const baseurl = "https://kj3tn339q4.execute-api.us-east-1.amazonaws.com/PROD"

    const carefullySetUserMessage = (text) => {
        const chineseCharacters = text.match(/[\u4e00-\u9fa5]/g) || [];
        const nonChineseWords = text.replace(/[\u4e00-\u9fa5]/g, ' ').split(/\s+/).filter(Boolean);
        const totalWords = chineseCharacters.length + nonChineseWords.length;

        if (totalWords <= 1000) {
            setUserMessage(text);
        } else {
            let wordCount = 0;
            let endIndex = 0;

            // Loop through the text to find the point where word count reaches 1000
            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                if (/[\u4e00-\u9fa5]/.test(char)) {
                    wordCount++;
                } else if (/\s/.test(char)) {
                    if (i > 0 && !/\s/.test(text[i - 1])) {
                        wordCount++;
                    }
                }
                if (wordCount >= 1000) {
                    endIndex = i;
                    break;
                }
            }
            if (wordCount < 1000 && endIndex < text.length) {
                endIndex = text.length;
            }
            setUserMessage(text.substring(0, endIndex));
        }
    }

    const renameConversation = async (newName) => {
        if (newName !== currentConversation.name) {
            try {
                const response = await fetch(
                    `${baseurl}/conversations`,
                    {
                        method: 'PATCH',
                        headers: {
                            "Authorization": `Bearer ${session.tokens.accessToken.toString()}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "conversation_id": currentConversation.id,
                            "conversation_name": newName
                        })
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error!\nResponse:\n${JSON.stringify(response, null, '\t')}`);
                }
                const newConversation = {...currentConversation, name: newName}
                setCurrentConversation(newConversation)
                setConversations(prevState => prevState.map(item => item.id === currentConversation.id ? newConversation : item))
            } catch (error) {
                console.error('Error fetching data:', error)
                setCurrentError("我们的系统出现了问题，给您带来不便，我们深表歉意。")
            }
        }
    }

    const deleteMessage = async () => {
        if (currentConversation.id.length) {
            try {
                const response = await fetch(
                    `${baseurl}/conversations/${currentConversation.id}`,
                    {
                        method: 'DELETE',
                        headers: {
                            "Authorization": `Bearer ${session.tokens.accessToken.toString()}`
                        }
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error!\nResponse:\n${JSON.stringify(response, null, '\t')}`);
                }
                setMessages([])
                setConversations(prevState => prevState.filter(item => item.id !== currentConversation.id))
                setCurrentConversation({name: "", id: ""})
            } catch (error) {
                console.error('Error fetching data:', error)
                setCurrentError("我们的系统出现了问题，给您带来不便，我们深表歉意。")
            }
        }
    }

    const sendMessage = async () => {
        if (userMessage.length) {
            try {
                setDisableUI(true)
                setMessages(prevItems => [...prevItems, {
                    message_index: messages.length > 0 ? messages[messages.length - 1].message_index + 1 : 1,
                    content: userMessage,
                    sender: userMessageType
                }])
                setUserMessage("")
                const response = await fetch(
                    `${baseurl}/conversations`,
                    {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${session.tokens.accessToken.toString()}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            "message_content": userMessage,
                            "conversation_id": currentConversation.id
                        })
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error!\nResponse:\n${JSON.stringify(result, null, '\t')}`);
                }
                const result = await response.json();
                if (result.conversation_id !== currentConversation.id) {
                    setConversations(prevItems => [...prevItems, {
                        name: result.conversation_name,
                        id: result.conversation_id
                    }])
                    setCurrentConversation({name: result.conversation_name, id: result.conversation_id})
                } else {
                    setMessages(prevItems => [...prevItems, {
                        message_index: result.message_index,
                        content: result.message_response,
                        sender: responseMessageType
                    }])
                }

            } catch (error) {
                console.error('Error fetching data:', error)
                setCurrentError("我们的系统出现了问题，给您带来不便，我们深表歉意。")
            } finally {
                setDisableUI(false)
            }
        }
    }

    const getMessages = async () => {
        if (currentConversation.id.length && !openConvoNameChangeModal) {
            try {
                const response = await fetch(
                    `${baseurl}/conversations/${currentConversation.id}`,
                    {
                        method: 'GET',
                        headers: {
                            "Authorization": `Bearer ${session.tokens.accessToken.toString()}`
                        }
                    }
                );
                if (!response.ok) {
                    // TODO: handle error
                    throw new Error(`HTTP error!\nResponse:\n${JSON.stringify(response, null, '\t')}`);
                }
                const result = await response.json();
                setMessages(result);
            } catch (error) {
                console.error('Error fetching data:', error)
                setCurrentError("我们的系统出现了问题，给您带来不便，我们深表歉意。")
            }
        } else {
            setMessages([]);
        }
    }

    const getConversations = async () => {
        try {
            const response = await fetch(
                `${baseurl}/conversations`,
                {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${session.tokens.accessToken.toString()}`
                    }
                }
            );
            if (!response.ok) {
                throw new Error(`HTTP error!\nResponse:\n${JSON.stringify(response, null, '\t')}`);
            }
            const result = await response.json();
            setConversations(result);
        } catch (error) {
            console.error('Error fetching data:', error)
            setCurrentError("我们的系统出现了问题，给您带来不便，我们深表歉意。")
        }
    }

    useEffect(() => {
        if (session) {
            getMessages().then();
        }
    }, [currentConversation])

    useEffect(() => {
        if (session) {
            getConversations().then();
        } else {
            fetchAuthSession().then(
                (session) => {
                    setSession(session);
                }
            )
        }
    }, [session])

    useEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current.scrollTop = scrollableAreaRef.current.scrollHeight;
        }
    }, [messages]);


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline enableColorScheme/>
            <Box sx={{display: 'flex', height: '100vh'}}>
                <Grid
                    item xs={2}
                    sx={{
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '0 0 15vw'
                    }}
                >
                    {/* Left side */}
                    <Box sx={{
                        height: '4vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: 1
                    }}>
                        <SvgIcon
                            component={TextBanner}
                            viewBox="40.652 10.636 280.505 84.005"
                            sx={{width: "100%", height: "100%"}}
                        />
                    </Box>
                    {/* New conversation button */}
                    <Button
                        sx={{
                            margin: '4%',
                            padding: '4%',
                            mb: '2%',
                            justifyContent: "space-between",
                            backgroundColor: currentConversation?.id === "" ? darkTheme.palette.action.hover : 'inherit',
                            '&:hover': {
                                border: 'none',
                                boxShadow: 'none',
                                '& .MuiButton-endIcon': {
                                    visibility: 'visible',
                                },
                            },
                            '& .MuiButton-endIcon': {
                                visibility: 'hidden',
                            },
                            whiteSpace: "pre-wrap",
                        }}
                        endIcon={<BorderColorRoundedIcon sx={{mb: '12%', p: '10%'}}/>}
                        onClick={() => setCurrentConversation({name: "", id: ""})}
                        disabled={conversations.length >= maxConversations}
                    >
                        {conversations.length >= maxConversations ? "对话数量已达上限，请删除闲置的对话以释放空间。" : "新对话"}
                    </Button>
                    <Box sx={{flex: '1', overflowY: 'auto', padding: '1%'}}>
                        {/* Conversation list */}
                        <Box sx={{margin: '10px', textAlign: 'left'}}>
                            {conversations.length === 0 ? (
                                <Typography variant="subtitle1" sx={{textAlign: 'center', my: '30%'}}>
                                    尚无对话
                                </Typography>
                            ) : (
                                conversations.slice().reverse().map((conversation, index) => (
                                    <Button
                                        key={index}
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => setCurrentConversation(conversation)}
                                        sx={{
                                            border: 'none',
                                            boxShadow: 'none',
                                            justifyContent: 'flex-start',
                                            textAlign: 'left',
                                            backgroundColor: currentConversation?.id === conversation.id ? darkTheme.palette.action.hover : 'inherit',
                                            '&:hover': {
                                                border: 'none',
                                                boxShadow: 'none',
                                            },
                                            textTransform: "none",
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {conversation.name}
                                    </Button>
                                )))}
                        </Box>
                    </Box>
                    {/* Account button */}
                    <Button
                        variant="outlined"
                        sx={{
                            border: 'none',
                            boxShadow: 'none',
                            textAlign: 'left',
                            margin: '4%',
                            padding: '3%',
                            mb: '2%',
                            justifyContent: "flex-start",
                            '&:hover': {
                                border: 'none',
                                boxShadow: 'none',
                            }
                        }}
                        startIcon={<PersonIcon/>}
                        onClick={() => setOpenSignOutModal(true)}
                    >
                        {user.signInDetails.loginId}
                    </Button>
                </Grid>
                <Grid item sx={{width: '100%'}}>
                    {/* Right side */}
                    <Paper elevation={2} sx={{height: '100%', width: '100%', borderRadius: 0}}>
                        {/* Conversation and options */}
                        <Box
                            sx={{
                                py: 2,
                                px: 4,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                height: "7%"
                            }}
                        >
                            <Box/>
                            <Button
                                sx={{
                                    '&:hover': {
                                        border: 'none',
                                        boxShadow: 'none',
                                    },
                                    '& .MuiButton-endIcon': {
                                        visibility: !currentConversation.id.length ? 'hidden' : null,
                                    },
                                    textTransform: "none",
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                                endIcon={<BorderColorRoundedIcon sx={{pl: "10%"}}/>}
                                onClick={() => setOpenConvoNameChangeModal(true)}
                                disabled={!currentConversation.id.length}
                            >
                                <Typography variant="h5" align="center">
                                    {currentConversation.name}
                                </Typography>
                            </Button>
                            <IconButton
                                edge="end"
                                color="primary"
                                disabled={!currentConversation.id}
                                onClick={() => {
                                    setOpenVerificationModal(true)
                                }}
                                sx={{display: !currentConversation.id.length ? 'none' : null}}
                            >
                                <DeleteOutlineIcon/>
                            </IconButton>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', height: '90%', width: '100%'}}>
                            <Box
                                sx={{flexGrow: 1, overflowY: 'auto', textAlign: 'center', px: '15%'}}
                                ref={scrollableAreaRef}
                            >
                                {/* Scrollable message area */}
                                <Box sx={{p: 2}}>
                                    {messages.map((message) => (
                                        message.sender === userMessageType ? (
                                            <Box
                                                key={message.message_index}
                                                display="flex"
                                                alignItems="left"
                                                justifyContent="right"
                                                paddingY='2%'
                                                sx={{ml: '10%', whiteSpace: "pre-wrap"}}
                                            >
                                                <Typography align="left">{message.content}</Typography>
                                                <PersonIcon sx={{ml: '2%'}}/>
                                            </Box>
                                        ) : (
                                            <Box
                                                key={message.message_index}
                                                display="flex"
                                                alignItems="left"
                                                justifyContent="left"
                                                paddingY='2%'
                                                sx={{mr: '10%', whiteSpace: "pre-wrap"}}
                                            >
                                                <SvgIcon
                                                    component={HeadLogo}
                                                    viewBox="0 0 118 118"
                                                    sx={{mr: '2%'}}
                                                />
                                                <Typography align="left">{message.content}</Typography>
                                            </Box>
                                        )
                                    ))}
                                </Box>
                            </Box>
                            {/* Message input */}
                            <Box sx={{p: 2, textAlign: 'center'}}>
                                <TextField
                                    label={messages.length >= maxMessages ? "此对话已达到消息限制" : "给智能助手发送信息"}
                                    variant="outlined"
                                    value={userMessage}
                                    multiline
                                    maxRows={6}
                                    onChange={(event) => carefullySetUserMessage(event.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{pr: 1}}>
                                                <IconButton
                                                    edge="end"
                                                    color="primary"
                                                    disabled={!userMessage.length || disableUI}
                                                    onClick={sendMessage}
                                                >
                                                    <ArrowForwardIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{mt: 2, width: '60%'}}
                                    disabled={messages.length >= maxMessages || (conversations.length >= maxConversations && !currentConversation.id.length)}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Box>
            <AreYouSureModal
                open={openVerificationModal}
                setOpen={setOpenVerificationModal}
                onConfirm={deleteMessage}
                message="此操作无法撤销！"
            />
            <EditConversationNameModal
                open={openConvoNameChangeModal}
                setOpen={setOpenConvoNameChangeModal}
                onConfirm={renameConversation}
            />
            <SignOutModal
                open={openSignOutModal}
                setOpen={setOpenSignOutModal}
                signOut={signOut}
                username={user.signInDetails.loginId}
            />
            <Snackbar
                open={currentError.length > 0}
                onClose={() => setCurrentError("")}
                sx={{justifyContent: "center"}}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    onClose={() => setCurrentError("")}
                    severity="error"
                    variant="filled"
                    sx={{
                        width: '100%',
                    }}
                >
                    {currentError}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default App;
